import axios from 'axios';
import config from '../config/config'


export const adminLogin = async (data)=>{
    console.log("api,",config.AdminAPI);
    console.log('data',data);
    try{
        const resp = await axios({
            'method':'POST',
            'url':`${config.AdminAPI}/adminlogin`,
            'data':data
            
        })
        console.log("returned response",resp.data)
        return resp.data;
    }catch(e){
        console.log("error on axios",e);
    }
}  


export const getCurrencylist = async(data)=>{
    try{
        const resp = await axios({
            'method':'GET',
            'url':`${config.AdminAPI}/currencylist`,
            "params" : data,
            "headers":{
                "Authorization":localStorage.getItem("Token")
            }
        })
        console.log("returned response",resp.data)
        return resp.data;
    }catch(e){
        console.log("error on axios",e);
    }
}

export const addCurrencyList = async (data)=>{
    try{
        const resp = await axios({
            'method':'POST',
            'url':`${config.AdminAPI}/addCurrencyList`,
            "data" : data,
            "headers":{
                "Authorization":localStorage.getItem("Token")
            }
        })
        console.log("returned response",resp.data)
        return resp.data;
    }catch(e){
        console.log("error on axios",e);
    }
}

export const getCmsList = async (data) =>{
    try{
        const resp = await axios({
            'method':'GET',
            'url':`${config.AdminAPI}/getCmsList`,
            "headers":{
                "Authorization":localStorage.getItem("Token")
            }
        })
        console.log("returned response",resp.data)
        return resp.data;
    }catch(e){
        console.log("error on axios",e);
    }
}

export const editCms = async (data) =>{
    try{
        const resp = await axios({
            'method':'POST',
            'url':`${config.AdminAPI}/editCms`,
            "data" : data,
            "headers":{
                "Authorization":localStorage.getItem("Token")
            }
        })
        console.log("returned response",resp.data)
        return resp.data;
    }catch(e){
        console.log("error on axios",e);
    }
}


export const getDappGategory = async (data) =>{
    try{
        const resp = await axios({
            'method':'GET',
            'url':`${config.AdminAPI}/getDappGategory`,
            "headers":{
                "Authorization":localStorage.getItem("Token")
            }
        })
        console.log("returned response",resp.data)
        return resp.data;
    }catch(e){
        console.log("error on axios",e);
    }
}


export const saveDappCategory = async (data) =>{
    try{
        const resp = await axios({
            'method':'POST',
            'url':`${config.AdminAPI}/saveDappCategory`,
            "data" : data, 
            "headers":{
                "Authorization":localStorage.getItem("Token")
            }
        })
        console.log("returned response",resp.data)
        return resp.data;
    }catch(e){
        console.log("error on axios",e);
    }
}


export const getDapps = async (data) =>{
    try{
        const resp = await axios({
            'method':'GET',
            'url':`${config.AdminAPI}/getDapps`,
            "data" : data, 
            "headers":{
                "Authorization":localStorage.getItem("Token")
            }
        })
        console.log("returned response",resp.data)
        return resp.data;
    }catch(e){
        console.log("error on axios",e);
    }
}

export const saveDapps = async (data) =>{
    try{
        const resp = await axios({
            'method':'POST',
            'url':`${config.AdminAPI}/saveDapps`,
            "data" : data, 
            "headers":{
                'Content-Type': 'multipart/form-data',
                "Authorization":localStorage.getItem("Token")
            }
        })
        console.log("returned response",resp.data)
        return resp.data;
    }catch(e){
        console.log("error on axios",e);
    }
}

export const SavePairToken = async(data)=>{
    try{
        console.log("datatata",data);
        const resp = await axios({
            'method':'POST',
            'url':`${config.AdminAPI}/savePairToken`,
            "data" : data,
            "headers":{
                "Authorization":localStorage.getItem("Token")
            }
        })
        console.log("returned response",resp.data)
        return resp.data;
    }catch(e){
        console.log("error on axios",e);
    }
}


export const getpoollist = async (data) =>{
    try{
        const resp = await axios({
            'method':'POST',
            'url':`${config.StakeAPI}/stakelist`,
           })
        console.log("returned response",resp.data)
        return resp.data;
    }catch(e){
        console.log("error on axios",e);
    }
}

export const getnetworklist = async (data) =>{
    try{
        const resp = await axios({
            'method':'POST',
            'url':`${config.UserAPI}/networklist`,
           })
        console.log("returned response",resp.data)
        return resp.data;
    }catch(e){
        console.log("error on axios",e);
    }
}


export const stake_add = async(data)=>{
    try{
        console.log("datatata",data);
        const resp = await axios({
            'method':'POST',
            'url':`${config.StakeAPI}/stakeadd`,
            "data" : data,
           
        })
        console.log("returned response",resp.data)
        return resp.data;
    }catch(e){
        console.log("error on axios",e);
    }
}
