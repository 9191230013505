import React, { Component, useEffect, useState } from 'react';
// import { Form } from 'react-bootstrap';
import {
    Card,
    CardHeader,
    CardBody,
    Row,
    Col,
    Button,
    Form,
    Input,
    FormGroup,
    CardFooter
} from "reactstrap";
import { isEmpty } from 'config/common';
import { useHistory, useParams } from "react-router-dom";
import { editCms, getnetworklist, stake_add } from '../../../axiosCalls/admin.axios'


export function StakeAdd(props) {
    const history = useHistory();
    
     const initData = {
        "_lpToken":"",
        "_allocPoint":0,
        "_lastRewardBlock": 0,
        "_depositFeeBP":0,
          "_withUpdate":true,
           "_rwdToken": "",
           "_lockedPeriod":0,
           "_isFixed":true,
        "_emisson":0,
        "_interval":0,
        "_poolLimit": 0,
        "tokenType":"",
        "from":"admin"
      }

    const [formData, setFormData] = useState(initData)
    const [error, seterror] = useState({})
    const [list, setlist] = useState([])


    useEffect(() => {
        get();
    }, [])

    const get = async () => {
        var gettingCms = await getnetworklist();
        console.log("gettingCurrency", gettingCms);
        setlist(gettingCms?.data ?? [])
    }
console.log("list",list)

    const onChange = (evt) => {
        seterror({})
        const {id,value,lable} = evt.target
        console.log("id,value,lable",id,value,lable, { ...formData, ...{ [id]: value } })
        let formdata = { ...formData, ...{ [id]: value } }
        setFormData(formdata)
    }

    const validation = async () => {
          if (isEmpty(formData._lpToken)) { error._lpToken = "_lpToken Can't be Empty" };
          if (isEmpty(formData._lastRewardBlock)) { error._lastRewardBlock = "_lastRewardBlock Can't be Empty" };
          if (isEmpty(formData._depositFeeBP)) { error._depositFeeBP = "_depositFeeBP Can't be Empty" };
          if (isEmpty(formData._rwdToken)) { error._rwdToken = "_rwdToken Can't be Empty" };
          if (isEmpty(formData._lockedPeriod)) { error._lockedPeriod = "_lockedPeriod Can't be Empty" };
          if (isEmpty(formData._emisson)) { error._emisson = "_emisson Can't be Empty" };
          if (isEmpty(formData.tokenType)) { error.tokenType = "tokenType Can't be Empty" };
          if (isEmpty(formData._poolLimit)) { error._poolLimit = "_poolLimit Can't be Empty" };

        return error
    }


    const submitting = async () => {
        const validate = await validation()
        console.log("validate",validate,formData)
        if (!isEmpty(validate)) {
            seterror(validate);
        } else {


            
            var updata = await stake_add(formData);
            if (updata.success) {
                alert("Edited successfully");
                history.push("/admin/stakelist")
            } else {
                alert("Not edited");
            }
        }
    }


    return (
        <>
        <div className="content">
            <Row>
                <Col md="12">
                    <Card>
                        <CardHeader>
                            <h5 className="title">Add Pool</h5>
                        </CardHeader>
                        <CardBody>
                            <Form>
                                <Row>
                                    <Col md="8">
                                        <FormGroup>
                                            <label>Alloc Point</label>
                                            <br></br><span style={{ color: 'red' }}>{error?._allocPoint??''}</span>
                                            <Input
                                                placeholder="Alloc Point"
                                                id="_allocPoint"
                                                type="text"
                                                onChange={(e) => onChange(e)}
                                            />
                                            <div><br></br>
                                                <label>LP Token</label>
                                                <br></br><span style={{ color: 'red' }}>{error?._lpToken??''}</span>
                                                <Input
                                                    placeholder="Lp Token"
                                                    id="_lpToken"
                                                    type="text"
                                                    onChange={(e) => onChange(e)}
                                                />
                                            </div>
                                            <div><br></br>
                                            <label>_depositFeeBPe</label>
                                                <br></br><span style={{ color: 'red' }}>{error && error._depositFeeBP}</span>
                                                <Input
                                                    placeholder="Deposit Fee BP"
                                                    id="_depositFeeBP"
                                                    name="tokenType"
                                                    type="text"
                                                    onChange={(e) => onChange(e)}
                                                >
                                                   
                                                </Input>
                                            </div>
                                            <div><br></br>
                                                <label>Reward Token</label>
                                                <br></br><span style={{ color: 'red' }}>{error?._rwdToken??''}</span>
                                                <Input
                                                    placeholder="Reward Token"
                                                    id="_rwdToken"
                                                    type="text"
                                                    onChange={(e) => onChange(e)}
                                                />
                                            </div>
                                            <div><br></br>
                                                <label>Locked Period( in seconds *)</label>
                                                <br></br><span style={{ color: 'red' }}>{error?._lockedPeriod??''}</span>
                                                <Input
                                                    placeholder="Locked Period( in seconds *)"
                                                    id="_lockedPeriod"
                                                    type="text"
                                                    onChange={(e) => onChange(e)}
                                                />
                                            </div>  <div><br></br>
                                                <label>Emisson (Reward Percentage)</label>
                                                <br></br><span style={{ color: 'red' }}>{error?._emisson??''}</span>
                                                <Input
                                                    placeholder="Emisson (Reward Percentage)"
                                                    id="_emisson"
                                                    type="text"
                                                    onChange={(e) => onChange(e)}
                                                />
                                            </div>  <div><br></br>
                                                <label>Interval (in seconds *)</label>
                                                <br></br><span style={{ color: 'red' }}>{error?._interval??''}</span>
                                                <Input
                                                    placeholder="Interval (generate reward per this value)"
                                                    id="_interval"
                                                    type="text"
                                                    onChange={(e) => onChange(e)}
                                                />
                                            </div>  <div><br></br>
                                                <label>Pool Limit</label>
                                                <br></br><span style={{ color: 'red' }}>{error?._poolLimit??''}</span>
                                                <Input
                                                    placeholder="Pool Limit"
                                                    id="_poolLimit"
                                                    type="text"
                                                    onChange={(e) => onChange(e)}
                                                />
                                            </div>  <div><br></br>
                                                <label>Token Type</label>
                                                <br></br><span style={{ color: 'red' }}>{error?._lpToken??''}</span>
                                                <Input
                                                    placeholder="Lp Token"
                                                    id="tokenType"
                                                    type="select"
                                                    onChange={(e) => onChange(e)}
                                                >
                                                    <option value={""}>select</option>
                                                   {list.map(item=>{
                                                    return <option value={item.type}>{item.type}</option> 
                                                   })}
                                                    
                                                </Input>
                                            </div>
                                        </FormGroup>
                                    </Col>
                                </Row>
                            </Form>
                        </CardBody>
                        <CardFooter>
                            <Button className="btn-fill" color="primary" type="submit" 
                            onClick={() => submitting()}
                            >
                                Save
                            </Button>
                        </CardFooter>
                    </Card>
                </Col>

            </Row>
        </div>
    </>
    )

}

export default StakeAdd
