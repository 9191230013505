/*!

=========================================================
* Black Dashboard React v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/black-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/black-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
// import axios from 'axios'
import { getpoollist} from '../../../axiosCalls/admin.axios'
// reactstrap components
import {
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    Table,
    Row,
    Col,
    Button
} from "reactstrap";

function StakeList() {

    const [list, setlist] = useState([]);
    console.log("statelist", list);
   
    useEffect(() => {
        get();
    }, [])

    const get = async () => {
        var gettingCms = await getpoollist();
        console.log("gettingCurrency", gettingCms);
        setlist(gettingCms?.data ?? [])
    }

    return (
        <div className="content">
                <Row>
                    <Col md="12">
                        <Card>
                            <CardHeader>
                                <CardTitle tag="h4">Pool List</CardTitle>
                                <Link to="/admin/stakeadd">Add</Link>
                            </CardHeader>

                            <CardBody>
                              

                                <Table className="tablesorter" responsive>

                                    <thead className="text-primary">

                                        <tr>
                                            <th>LP Token Currency</th>
                                            <th>LP Token Address</th>
                                            <th>Reward Token Currency</th>
                                            <th>Reward Token Address</th>
                                            <th>TokenType</th>
                                            <th>Pool Limit</th>
                                        </tr>

                                    </thead>
                                    <tbody>
                                        {list.map((res, index) => {
                                            return (
                                                <tr>
                                                    <td>{index + 1}</td>
                                                    <td>{res._lpToken}</td>
                                                    <td>{res._rwdToken}</td>
                                                    <td>{res.tokenType}</td>
                                                    <td>{res.rwdcurrencysymbol}</td>
                                                    <td>{res.tokenType}</td>
                                                    <td>{res._poolLimit}</td>
                                                    <td className="text-center"></td>
                                                </tr>)
                                        })}

                                    </tbody>
                                </Table>

                            </CardBody>
                        </Card>
                    </Col>

                </Row>
            </div>
    );
}

export default StakeList;
