import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom"
import { useHistory } from "react-router-dom";
import axios from 'axios';
import { adminLogin } from '../../axiosCalls/admin.axios.js'
import { isEmpty } from '../../config/common'
// import { updateNamedExports } from "typescript";


function Login() {
    let histroy = useHistory();
    let { push } = useHistory();

    const [isSubmitted, setIsSubmitted] = useState(false);
    // console.log("state", profile);

    const [error, seterror] = useState({
        nameorEmail: "",
        Password: "",
    })
    const [Input, setInput] = useState({
        nameorEmail: "",
        Password: ""
    })

    const oninputchange = (e) => {
        seterror({})
        setInput({ ...Input, [e.target.name]: e.target.value });
    }

    // console.log("inpt", Input);
    // console.log("errrr", error);
    const validation = async () => {
        if (isEmpty(Input.Password)) {
            let passerr = { ...error, ...{ ["Password"]: "Password mand1itatory" } }
            seterror(passerr);
        }
        if (isEmpty(Input.nameorEmail)) {
            let mailerr = { ...error, ...{ ["nameorEmail"]: "name or Email manditatory" } }
            seterror(mailerr);
        }
        if (isEmpty(Input.Password) && isEmpty(Input.nameorEmail)) {
            let mailerr = { ...error, ...{ ["nameorEmail"]: "name or Email manditatory", ["Password"]: "Password mand1itatory" } }
            seterror(mailerr);
        }
        if (!isEmpty(Input.nameorEmail) && !isEmpty(Input.Password)) {
            return true
        }
    }

    const handleSubmit = async (event) => {

        event.preventDefault();

        var validate = await validation();
        if (validate === true) {
            var sendData = {
                nameorEmail: Input.nameorEmail,
                Password: Input.Password
            }
            var login =await adminLogin(sendData)
            console.log("loginnn", login);
            if(login.success === "success"){
                localStorage.setItem("Token",login.token);
                push('/admin/currencyCryptoList');
            }
        }

    };

    // JSX code for login form
    const renderForm = (
        <div className="form">
            <form onSubmit={handleSubmit}>
                <div className="input-container">
                    <label>UserName OR Email</label>
                    <input type="text" name="nameorEmail" onChange={(e) => oninputchange(e)} />
                    {/* {renderErrorMessage("email")} */}
                    <span style={{ color: 'red' }}>{error && error.nameorEmail}</span>
                </div>
                <div className="input-container">
                    <label>Password </label>
                    <input type="password" name="Password" onChange={(e) => oninputchange(e)} />
                    <span style={{ color: 'red' }}>{error && error.Password}</span>
                    {/* {renderErrorMessage("Password")} */}
                </div>
                <div className="button-container">
                    <input type="submit" />
                </div>
            </form>
        </div>
    );

    return (
        <div className="app">
            <div className="login-form">
                <div className="title">Sign In</div>
                {isSubmitted ? <div>User is successfully logged in</div> : renderForm}
            </div>
        </div>
    );
}

export default Login;

