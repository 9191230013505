/*!

=========================================================
* Black Dashboard React v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/black-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/black-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";

// import  translate from 'google-translate-free';
import axios from 'axios'
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardText,
  FormGroup,
  Form,
  Input,
  Row,
  Col
} from "reactstrap";


function USERadd() {
  let history = useHistory();
  const [error, seterror] = useState({
    Username:"",
    Email:"",
    
  })

  const [user, setuser] = useState({
    Username:"",
    Email:"",
    Password:""

  })
  console.log("sataelang", user);
  const oninputchange = async (e) => {
    setuser({ ...user, [e.target.name]: e.target.value })
  }

  const onClick = () => {

    if (!user.Username) {
      error.Username = " name is  required"
      seterror({ ...error, Username: " name is  not  empty" })
    }
    else {


      axios({
        method: 'POST',
        url: 'http://localhost:3033/api/usersave',
        data: user

      }).then((response) => {
        console.log(response);
        // localStorage.setItem("reg", JSON.stringify(response.data.result))
        history.push('/admin/userlist')
      }).catch(err => {
        console.log(err);
      })

    }
  }

 return (
    <>
      <div className="content">
        <Row>
          <Col md="12">
            <Card>
              <CardHeader>
                <h5 className="title">UserAdd</h5>
              </CardHeader>
              <CardBody>
                <Form>
                 <Row>
                   <Col md="8">
                     <FormGroup>
                       <label>Username</label>
                       <Input
                         cols="80"
                         placeholder="Username"
                         rows="1"
                         id="Username"
                         name="Username"
                         type="textarea"

                         onChange={(e) => oninputchange(e)}
                       />
                        {/* <span style={{ color: 'red' }}>{error && error.Username}</span> */}
                        <div>
                          <label>Email</label>

                          <Input
                            cols="80"
                            placeholder="Email address"
                            rows="1"
                            id="Email"
                            name="Email"
                            type="Email"
                            
                            onChange={(e) => oninputchange(e)}

                          />
                         
                        </div>
                        <div>
                          <label>Password</label>
                          <Input
                            cols="80"
                            placeholder="Password"
                            rows="1"
                            id="Password"
                            name="Password"
                            type="password"
                            
                            onChange={(e) => oninputchange(e)}

                          />
                         
                        </div>
                      </FormGroup>
                    </Col>
                  </Row>
                </Form>
              </CardBody>
              <CardFooter>
                <Button className="btn-fill" color="primary" type="submit" onClick={() => onClick()}>
                  Save
                </Button>
              </CardFooter>
            </Card>
          </Col>

        </Row>
      </div>
    </>
  );
}

export default USERadd;
