/*!

=========================================================
* Black Dashboard React v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/black-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/black-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import translate from "translate";
// import  translate from 'google-translate-free';
import axios from 'axios'
// reactstrap components
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    CardText,
    FormGroup,
    Form,
    Input,
    Row,
    Col
} from "reactstrap";

function Ladd() {
    let history = useHistory();
    const [error, seterror] = useState({
        English: ""
    })

    const [lang, setlang] = useState({
        key: "",
        English: "",
        Spanish: ""

    })
    console.log("sataelang", lang);
    const oninputchange = async (e) => {
        setlang({ ...lang, [e.target.name]: e.target.value })
    }

    const onClick = () => {

        if (!lang.English) {
            error.English = " English is  required"
            seterror({ ...error, English: " English is  not  empty" })
        }
        else {


            axios({
                method: 'POST',
                url: 'http://localhost:3033/api/save',
                data: lang

            }).then((response) => {
                console.log(response);
                localStorage.setItem("reg", JSON.stringify(response.data.result))
                history.push('/admin/language')
            }).catch(err => {
                console.log(err);
            })

        }
    }

    return (
        <>
            <div className="content">
                <Row>
                    <Col md="12">
                        <Card>
                            <CardHeader>
                                <h5 className="title"></h5>
                            </CardHeader>
                            <CardBody>
                                <Form>
                                    <Row>
                                        <Col md="8">
                                            <FormGroup>
                                                <label>key</label>

                                                <Input
                                                    cols="80"
                                                    placeholder=""
                                                    rows="1"
                                                    id="key"
                                                    name="key"
                                                    type="textarea"

                                                    onChange={(e) => oninputchange(e)}
                                                />
                                                <div>
                                                    <label>English</label>

                                                    <Input
                                                        cols="80"
                                                        placeholder=""
                                                        rows="1"
                                                        id="English"
                                                        name="English"
                                                        type="textarea"
                                                        value={lang.English}
                                                        onChange={(e) => oninputchange(e)}

                                                    />
                                                    <span style={{ color: 'red' }}>{error && error.English}</span>
                                                </div>
                                                <label>Spanish</label>
                                                <Input
                                                    cols="80"
                                                    placeholder=""
                                                    rows="1"
                                                    id="Spanish"
                                                    name="Spanish"
                                                    value={lang.Spanish}
                                                    type="textarea"
                                                    onChange={(e) => oninputchange(e)}
                                                />

                                            </FormGroup>
                                        </Col>
                                    </Row>
                                </Form>
                            </CardBody>
                            <CardFooter>
                                <Button className="btn-fill" color="primary" type="submit" onClick={() => onClick()}>
                                    Save
                                </Button>
                            </CardFooter>
                        </Card>
                    </Col>

                </Row>
            </div>
        </>
    );
}

export default Ladd;
