/*!

=========================================================
* Black Dashboard React v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/black-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/black-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
// import axios from 'axios'
import { getDappGategory } from '../../../axiosCalls/admin.axios'
// reactstrap components
import {
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    Table,
    Row,
    Col,
    Button
} from "reactstrap";

function DappGategory() {

    const [list, setlist] = useState([]);
    console.log("statelist", list);
    let history = useHistory()
    // var user = JSON.parse(localStorage.getItem('reg'));
    // console.log("dfggggggggg",user)

    useEffect(() => {
        get();
    }, [])

    const get = async () => {
        var gettingCms = await getDappGategory();
        console.log("gettingCurrency", gettingCms);
        setlist(gettingCms?.data??[])
    }

    return (
        <>
            <div className="content">
                <Row>
                    <Col md="12">
                        <Card>
                            <CardHeader>
                                <CardTitle tag="h4">Dapp Gategory List</CardTitle>
                                <Link to="/admin/AddappCategory">Add</Link>
                            </CardHeader>

                            <CardBody>
                                {console.log('liastttt', list)}


                                <Table className="tablesorter" responsive>

                                    <thead className="text-primary">

                                        <tr>
                                            <th>S.No</th>
                                            <th>categoryName</th>
                                            {/* <th>Content</th> */}
                                            {/* <th className="text-center">Salary</th> */}
                                        </tr>

                                    </thead>
                                    <tbody>
                                        {list.map((res, index) => {
                                            return (
                                                <tr>
                                                    <td>{index + 1}</td>
                                                    <td>{res.categoryName}</td>
                                                    {/* <td>{res.content}</td> */}
                                                    {/* <Link to="/admin/language-edit" state={{data:res}}>Edit</Link> */}
                                                    {/* <Button onClick={() => {
                                                        history.push('/admin/editCms', { state: res })
                                                    }}>edit</Button> */}
                                                    <td className="text-center"></td>
                                                </tr>)
                                        })}

                                    </tbody>
                                </Table>

                            </CardBody>
                        </Card>
                    </Col>

                </Row>
            </div>
        </>
    );
}

export default DappGategory;
