/*!

=========================================================
* Black Dashboard React v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/black-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/black-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { isEmpty } from "config/common";
import { saveDappCategory } from "axiosCalls/admin.axios";
// import  translate from 'google-translate-free';
import axios from 'axios'
// reactstrap components
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    CardText,
    FormGroup,
    Form,
    Input,
    Row,
    Col
} from "reactstrap";

function Adddappcategory() {
    let history = useHistory();
    const [error, seterror] = useState({})

    const [categoryAdd, setcategoryAdd] = useState({
        categoryName: "",
    })

    console.log("sataelang", categoryAdd);

    const oninputchange = async (e) => {
        seterror({})
        setcategoryAdd({ ...categoryAdd, [e.target.name]: e.target.value })
    }


    const validation = async () => {
        var error = {};
        if (isEmpty(categoryAdd.categoryName)) { error.categoryName = "category Name can't be empty" }
        return error
    }



    const onClicking = async () => {
        var Gell = await validation();
        console.log("val", Gell);
        if (!isEmpty(Gell)) { seterror(Gell) }
        else {
            var savedData = await saveDappCategory(categoryAdd)
            console.log("asaafasefrefef", savedData);
            if (savedData.success == "success") {
                alert(savedData.msg);
                history.push("/admin/dappCategoryList")
            }else{
                alert("Dapp category Not created")
            }
        }
    }

    return (
        <>
            <div className="content">
                <Row>
                    <Col md="12">
                        <Card>
                            <CardHeader>
                                <h5 className="title">Dapp category Add</h5>
                            </CardHeader>
                            <CardBody>
                                <Form>
                                    <Row>
                                        <Col md="8">
                                            <FormGroup>
                                                <label>Category Name</label>
                                                <br></br><span style={{ color: 'red' }}>{error && error.categoryName}</span>
                                                <Input
                                                    placeholder="category Name"
                                                    id="categoryName"
                                                    name="categoryName"
                                                    type="text"
                                                    onChange={(e) => oninputchange(e)}
                                                />
                                                {/* <div><br></br>
                                                    <label>Curency Symbol</label>
                                                    <br></br><span style={{ color: 'red' }}>{error && error.currencySymbol}</span>
                                                    <Input
                                                        placeholder="CurrencySymbol"
                                                        id="currencySymbol"
                                                        name="currencySymbol"
                                                        type="text"
                                                        onChange={(e) => oninputchange(e)}
                                                    />
                                                </div>
                                                <div><br></br>
                                                    <label>Token Type</label>
                                                    <br></br><span style={{ color: 'red' }}>{error && error.tokenType}</span>
                                                    <Input
                                                        // placeholder="tokenType"
                                                        id="tokenType"
                                                        name="tokenType"
                                                        type="select"
                                                        onChange={(e) => oninputchange(e)}
                                                    >
                                                        <option value={""}>select</option>
                                                        <option value={"ETH20"}>ETH20</option>
                                                        <option value={"TRC20"}>TRC20</option>
                                                        <option value={"BEP20"}>BEP20</option>
                                                        <option value={"BTC"}>BTC</option>
                                                    </Input>
                                                </div> */}

                                            </FormGroup>
                                        </Col>
                                    </Row>
                                </Form>
                            </CardBody>
                            <CardFooter>
                                <Button className="btn-fill" color="primary" type="submit" onClick={() => onClicking()}>
                                    Save
                                </Button>
                            </CardFooter>
                        </Card>
                    </Col>

                </Row>
            </div>
        </>
    );
}

export default Adddappcategory;
