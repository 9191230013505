/*!

=========================================================
* Black Dashboard React v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/black-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/black-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { isEmpty } from "config/common";
import { addCurrencyList } from "axiosCalls/admin.axios";
// import  translate from 'google-translate-free';
import axios from 'axios'
// reactstrap components
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    CardText,
    FormGroup,
    Form,
    Input,
    Row,
    Col
} from "reactstrap";

function Tokenadd() {
    let history = useHistory();
    const [error, seterror] = useState({})

    const [cryptoAdd, setCryptoAdd] = useState({
        contractAddress: "",
        currencyName: "",
        currencySymbol: "",
        tokenType: "",
        decimals: "",
        minABI: ''
    })

    console.log("sataelang", cryptoAdd);

    const oninputchange = async (e) => {
        seterror({})
        setCryptoAdd({ ...cryptoAdd, [e.target.name]: e.target.value })
    }


    const validation = async () => {
        var error = {};
        if (isEmpty(cryptoAdd.currencyName)) { error.currencyName = "Currency Name can't be empty" }
        if (isEmpty(cryptoAdd.currencySymbol)) { error.currencySymbol = "currency Symbol can't be empty" }
        if (isEmpty(cryptoAdd.tokenType)) { error.tokenType = "token Type can't be empty" }
        if (isEmpty(cryptoAdd.contractAddress)) { error.contractAddress = "contractAddress can't be empty" }
        if (isEmpty(cryptoAdd.decimals)) { error.decimals = "decimals can't be empty" }
        if (isEmpty(cryptoAdd.minABI)) { error.minABI = "minAbi can't be empty" }
        return error
    }



    const onClicking = async () => {
        var Gell = await validation();
        console.log("val", Gell);
        if (!isEmpty(Gell)) { seterror(Gell) }
        else {
            var sendData = { ...cryptoAdd, ...{ ["type"]: "Token" } }
            // console.log("sendaaaaa",sendData);
            var savedData = await addCurrencyList(sendData)
            console.log("asaafasefrefef", savedData);
            if(savedData.success == "success"){
                alert(savedData.msg);
                history.push("/admin/currencyTokenList")
            }else{
                alert("Token Not Added")
            }
        }
    }

    return (
        <>
            <div className="content">
                <Row>
                    <Col md="12">
                        <Card>
                            <CardHeader>
                                <h5 className="title">Currency Token Add</h5>
                            </CardHeader>
                            <CardBody>
                                <Form>
                                    <Row>
                                        <Col md="8">
                                            <FormGroup>
                                                <div>
                                                    <label>ContractAddress</label>
                                                    <br></br><span style={{ color: 'red' }}>{error && error.contractAddress}</span>
                                                    <Input
                                                        placeholder="ContractAddress"
                                                        id="contractAddress"
                                                        name="contractAddress"
                                                        type="text"
                                                        onChange={(e) => oninputchange(e)}
                                                    />
                                                </div>

                                                <div><br></br>
                                                    <label>Currency Name</label>
                                                    <br></br><span style={{ color: 'red' }}>{error && error.currencyName}</span>
                                                    <Input
                                                        placeholder="CurrencyName"
                                                        id="currencyName"
                                                        name="currencyName"
                                                        type="text"
                                                        onChange={(e) => oninputchange(e)}
                                                    />
                                                </div>

                                                <div><br></br>
                                                    <label>Curency Symbol</label>
                                                    <br></br><span style={{ color: 'red' }}>{error && error.currencySymbol}</span>
                                                    <Input
                                                        placeholder="CurrencySymbol"
                                                        id="currencySymbol"
                                                        name="currencySymbol"
                                                        type="text"
                                                        onChange={(e) => oninputchange(e)}
                                                    />
                                                </div>

                                                <div><br></br>
                                                    <label>Decimal</label>
                                                    <br></br><span style={{ color: 'red' }}>{error && error.decimals}</span>
                                                    <Input
                                                        placeholder="Decimals"
                                                        id="decimals"
                                                        name="decimals"
                                                        type="number"
                                                        onChange={(e) => oninputchange(e)}
                                                    />
                                                </div>

                                                <div><br></br>
                                                    <label>Token Type</label>
                                                    <br></br><span style={{ color: 'red' }}>{error && error.tokenType}</span>
                                                    <Input
                                                        // placeholder="tokenType"
                                                        id="tokenType"
                                                        name="tokenType"
                                                        type="select"
                                                        onChange={(e) => oninputchange(e)}
                                                    >
                                                        <option value={""}>Select</option>
                                                        <option value={"ETH20"}>ETH20</option>
                                                        <option value={"TRC20"}>TRC20</option>
                                                        <option value={"BEP20"}>BEP20</option>
                                                        <option value={"BTC"}>BTC</option>
                                                    </Input>
                                                </div>

                                                <div><br></br>
                                                    <label>Min Abi</label>
                                                    <br></br><span style={{ color: 'red' }}>{error && error.minABI}</span>
                                                    <Input
                                                        placeholder="minABI"
                                                        id="minABI"
                                                        name="minABI"
                                                        type="textarea"
                                                        onChange={(e) => oninputchange(e)}
                                                    />
                                                </div>

                                            </FormGroup>
                                        </Col>
                                    </Row>
                                </Form>
                            </CardBody>
                            <CardFooter>
                                <Button className="btn-fill" color="primary" type="submit" onClick={() => onClicking()}>
                                    Save
                                </Button>
                            </CardFooter>
                        </Card>
                    </Col>

                </Row>
            </div>
        </>
    );
}

export default Tokenadd;
