/*!

=========================================================
* Black Dashboard React v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/black-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/black-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import axios from 'axios'
// reactstrap components
import {
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    Table,
    Row,
    Col,
    Button
} from "reactstrap";

function USERList() {

    const [list, setlist] = useState([]);
    console.log("statelist", list);
    let history = useHistory()


    useEffect(() => {

        axios({
            method: 'GET',
            url: 'http://localhost:3033/api/getuserprofile',

        }).then((res) => {
            console.log("datalist", res.data);
            setlist(res.data)

        })
    }
        , [])

    return (
        <>
            <div className="content">
                <Row>
                    <Col md="12">
                        <Card>
                            <CardHeader>
                                <CardTitle tag="h4">UserProfile</CardTitle>
                                <Link to="/admin/useradd">Add</Link>
                            </CardHeader>

                            <CardBody>
                                {console.log('liastttt', list)}


                                <Table className="tablesorter" responsive>

                                    <thead className="text-primary">

                                        <tr>
                                            <th>Username</th>
                                            <th>Email</th>
                                        </tr>

                                    </thead>
                                    <tbody>
                                        {list.map((res) => {
                                            return (
                                                <tr>
                                                    <td>{res.Username}</td>
                                                    <td>{res.Email}</td>
                                                    <td className="text-center"></td>
                                                </tr>)
                                        })}

                                    </tbody>
                                </Table>

                            </CardBody>
                        </Card>
                    </Col>

                </Row>
            </div>
        </>
    );
}

export default USERList;
