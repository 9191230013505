/*!

=========================================================
* Black Dashboard React v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/black-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/black-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

import React, { useState,useEffect } from "react";
import axios from 'axios'
import { useHistory,useLocation } from "react-router-dom";
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardText,
  FormGroup,
  Form,
  Input,
  Row,
  Col
} from "reactstrap";



function LEdit(props) {
  // const [key,setkey]=useState('')
  const [English,setenglish]=useState('')
  const[spanish,setSpanish]=useState('')

  const [error, seterror] = useState({
    English: ""
  })
  
  const location = useLocation();
  console.log("prorpropro", location);
 
 
  
 useEffect(()=>{
  const  data = location.state;
  if(data){
    // setkey(data.state.key)
    setenglish(data.state.English)
    setSpanish(data.state.Spanish)

  }
  
    }
  ,[])


    let history=useHistory();
  // var take = JSON.parse(localStorage.getItem('reg'));
  // console.log("take",take);
 

// const [edit,setedit]=useState({
//   key: "",
//   English: "",
//   Spanish: ""
// })
// console.log("stata",edit);
  // useEffect(()=>{
    
  //   axios({
  //       method : 'POST',
  //       url : 'http://localhost:3033/api/getapi',
  //       data :{id:take._id} 
  //   }).then((res)=>{
  //       console.log("profile data",res.data);
  //      setedit({...edit,...res.data})
        
  //    })
   
   
  // }
  // ,[])


  // useEffect(()=>{
    
  //   axios({
  //       method : 'GET',
  //       url : 'http://localhost:3033/api/getdata',
        
  //   }).then((res)=>{
  //       console.log(" language",res.data);
  //    })
  //   }
  // ,[])




  const oninputchange = (e) => {
    console.log('------------');
    // if(e.target.name=='key'){
    //   setkey(e.target.value)
    // }
    if(e.target.name=='English'){
      setenglish(e.target.value)
    }
    if(e.target.name=='Spanish'){
      setSpanish(e.target.value)
    }
  }
  
const onclick=()=>{

  if (!English.English) {
    error.English = " English is  required"
    seterror({ ...error, English: " English is  not  empty" })
  }



    const sendData = {
      
        English: English,
        Spanish: spanish
    }
    console.log("eeeee",sendData);

 axios({
        method : 'POST',
        url : 'http://localhost:3033/api/edit',
        data : sendData
    }).then((res)=>{
        console.log("editprofile data",res.data);
        history.push('/admin/language')
        
     }).catch(err => {
        console.log(err);
    })


}

 return (
    <>
      <div className="content">
        <Row>
          <Col md="12">
            <Card>
              <CardHeader>
                <h5 className="title">Edit Profiles</h5>
              </CardHeader>
              <CardBody>
                <Form>
                  <Row>
                  </Row>
                  <Row>
                    <Col className="pr-md-1" md="6">
                      {/* <FormGroup>
                        <label>First Name</label>
                        <Input
                          defaultValue="Mike"
                          placeholder="Company"
                          type="text"
                        />
                      </FormGroup> */}
                    </Col>
                    <Col className="pl-md-1" md="6">
                      {/* <FormGroup>
                        <label>Last Name</label>
                        <Input
                          defaultValue="Andrew"
                          placeholder="Last Name"
                          type="text"
                        />
                      </FormGroup> */}
                    </Col>
                  </Row>
                  <Row>
                    <Col md="12">
                      {/* <FormGroup>
                        <label>Address</label>
                        <Input
                          defaultValue="Bld Mihail Kogalniceanu, nr. 8 Bl 1, Sc 1, Ap 09"
                          placeholder="Home Address"
                          type="text"
                        />
                      </FormGroup> */}
                    </Col>
                  </Row>
                  <Row>
                    <Col className="pr-md-1" md="4">
                      {/* <FormGroup>
                        <label>City</label>
                        <Input
                          defaultValue="Mike"
                          placeholder="City"
                          type="text"
                        />
                      </FormGroup> */}
                    </Col>
                    <Col className="px-md-1" md="4">
                      {/* <FormGroup>
                        <label>Country</label>
                        <Input
                          defaultValue="Andrew"
                          placeholder="Country"
                          type="text"
                        />
                      </FormGroup> */}
                    </Col>
                    <Col className="pl-md-1" md="4">
                      {/* <FormGroup>
                        <label>Postal Code</label>
                        <Input placeholder="ZIP Code" type="number" />
                      </FormGroup> */}
                    </Col>
                  </Row>
                  <Row>
                    <Col md="8">
                      <FormGroup>
                        {/* <label>About Me</label>
                      
                        <Input
                          cols="80"
                          defaultValue="Lamborghini Mercy, Your chick she so thirsty, I'm in
                            that two seat Lambo."
                          placeholder="Here can be your description"
                          rows="4"
                          type="textarea"
                          
                        /> */}

                      <label>key</label>

                       <Input
                          cols="80"
                          placeholder=""
                          rows="1"
                          // id="key"
                          // name="key"
                          type="textarea"
                          value={location.state.state.key}
                          
                        
                        />
                        <label>English</label>

                        <Input
                          cols="80"
                          placeholder=""
                          rows="1"
                          id="English"
                          name="English"
                          type="textarea"
                          value={English}
                          onChange={(e) => oninputchange(e)}
                         
                        />
                         <span style={{ color: 'red' }}>{error && error.English}</span>
                        <label>Spanish</label>
                        <Input
                          cols="80"
                          placeholder=""
                          rows="1"
                          id="Spanish"
                          name="Spanish"
                          value={spanish}
                          type="textarea"
                          onChange={(e) => oninputchange(e)}
                          
                        />

                      </FormGroup>
                    </Col>
                  </Row>
                </Form>
              </CardBody>
              <CardFooter>
                <Button className="btn-fill" color="primary" type="submit" onClick={() => onclick()}>
                Edit
                </Button>
              </CardFooter>
            </Card>
          </Col>
          
        </Row>
      </div>
    </>
  );
}

export default LEdit;
