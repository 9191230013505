/*!

=========================================================
* Black Dashboard React v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/black-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/black-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { isEmpty } from "config/common";
import { getDappGategory, saveDapps } from "../../../axiosCalls/admin.axios";
// import  translate from 'google-translate-free';
import axios from 'axios'
// reactstrap components
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    CardText,
    FormGroup,
    Form,
    Input,
    Row,
    Col
} from "reactstrap";

function AddDapps() {

    let history = useHistory();

    const [error, seterror] = useState({})
    const [cats, setCats] = useState([])
    const [cryptoAdd, setCryptoAdd] = useState({
        title: "",
        description: "",
        url: "",
        // appimage: {},
        categoryId: ''
    })

    useEffect(() => {
        getdappCategory()
    }, [])

    const getdappCategory = async () => {
        var dappCats = await getDappGategory({ type: "category" });
        console.log("dappCats", dappCats);
        setCats(dappCats.data)
    }

    const oninputchange = async (e) => {
        const { name, files, value } = e.target
        seterror({})
        if (name) { setCryptoAdd({ ...cryptoAdd, [name]: value }) }
        if (files) { setCryptoAdd({ ...cryptoAdd, [name]: files[0] }) }

    }
    console.log("cryptoAdd", cryptoAdd);

    const validation = async () => {
        var error = {};
        if (isEmpty(cryptoAdd.title)) { error.title = "title  can't be empty" }
        if (isEmpty(cryptoAdd.description)) { error.description = "description can't be empty" }
        if (isEmpty(cryptoAdd.url)) { error.url = "url can't be empty" }
        // if (isEmpty(cryptoAdd.appimage)) { error.appimage = "appimage can't be empty" }
        if (isEmpty(cryptoAdd.categoryId)) { error.categoryId = "categoryId can't be empty" }
        // if (isEmpty(cryptoAdd.minABI)) { error.minABI = "minAbi can't be empty" }
        return error
    }



    const onClicking = async () => {
        var Gell = await validation();
        console.log("val", Gell);
        if (!isEmpty(Gell)) { seterror(Gell) }
        else {
            console.log("geejejej");
            var formdata = new FormData;
            formdata.append("title", cryptoAdd?.title)
            formdata.append("description", cryptoAdd?.description)
            formdata.append("url", cryptoAdd?.url)
            formdata.append("appimage", cryptoAdd?.appimage)
            formdata.append("categoryId", cryptoAdd?.categoryId)
            // console.log(formdata.get('appimage'));
            var resp = await saveDapps(formdata);
            console.log(":ressssp", resp);
            if (resp.success === "success") {
                history.push("/admin/DappsList")
            } else {
                alert("Not created")
            }
        }
    }

    console.log("cryptoAdd", cryptoAdd);
    return (
        <>
            <div className="content">
                <Row>
                    <Col md="12">
                        <Card>
                            <CardHeader>
                                <h5 className="title">Currency Token Add</h5>
                            </CardHeader>
                            <CardBody>
                                <Form>
                                    <Row>
                                        <Col md="8">
                                            <FormGroup>
                                                <div>
                                                    <label>title</label>
                                                    <br></br><span style={{ color: 'red' }}>{error && error.title}</span>
                                                    <Input
                                                        placeholder="title"
                                                        id="title"
                                                        name="title"
                                                        type="text"
                                                        onChange={(e) => oninputchange(e)}
                                                    />
                                                </div>

                                                <div><br></br>
                                                    <label>description</label>
                                                    <br></br><span style={{ color: 'red' }}>{error && error.description}</span>
                                                    <Input
                                                        placeholder="description"
                                                        id="description"
                                                        name="description"
                                                        type="text"
                                                        onChange={(e) => oninputchange(e)}
                                                    />
                                                </div>

                                                <div><br></br>
                                                    <label>url</label>
                                                    <br></br><span style={{ color: 'red' }}>{error && error.url}</span>
                                                    <Input
                                                        placeholder="url"
                                                        id="url"
                                                        name="url"
                                                        type="text"
                                                        onChange={(e) => oninputchange(e)}
                                                    />
                                                </div>
                                                <div><br></br>
                                                    <label>category</label>
                                                    <br></br><span style={{ color: 'red' }}>{error && error.categoryId}</span>
                                                    <Input
                                                        // placeholder="tokenType"
                                                        id="categoryId"
                                                        name="categoryId"
                                                        type="select"
                                                        onChange={(e) => oninputchange(e)}
                                                    >
                                                        <option value={""}>Select</option>
                                                        {cats.map((val) => (
                                                            // {console.lo"valalla",val);}
                                                            <option value={val._id}>{val.categoryName}</option>
                                                        ))}

                                                    </Input>
                                                </div>
                                            </FormGroup>
                                            <input type={"file"} name="appimage" id="appimage" onChange={(e) => oninputchange(e)}></input>
                                            {/* {cryptoAdd?.appimage && isEmpty(!cryptoAdd?.appimage) &&
                                                <img style={{ height: "100px", width: "100px" }} src={URL.createObjectURL(cryptoAdd?.appimage)} ></img>
                                                //  <img style={{ height: "100px", width: "100px" }} src={`${config.ImG}/admin/cms/${cryptoAdd?.appimage}`} ></img>
                                            } */}
                                            <div>
                                                <br></br>
                                            {
                                                cryptoAdd.appimage &&
                                                (
                                                    cryptoAdd?.appimage == {} ? <></> : <img style={{ height: "100px", width: "100px" }} src={URL.createObjectURL(cryptoAdd?.appimage)} ></img>
                                                )
                                            }
                                            </div>
                                        </Col>
                                    </Row>
                                </Form>
                            </CardBody>
                            <CardFooter>
                                <Button className="btn-fill" color="primary" type="submit" onClick={() => onClicking()}>
                                    Save
                                </Button>
                            </CardFooter>
                        </Card>
                    </Col>

                </Row>
            </div>
        </>
    );
}

export default AddDapps;
