import React, { Component, useEffect, useState } from 'react';
// import { Form } from 'react-bootstrap';
import {
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    Table,
    Row,
    Col,
    Button
} from "reactstrap";
import { isEmpty } from 'config/common';
import { useHistory, useParams } from "react-router-dom";
import { CKEditor } from 'ckeditor4-react';
import { editCms } from '../../../axiosCalls/admin.axios'


export function EditCms(props) {
    const history = useHistory();
    //   useEffect(() => {
    //     bsCustomFileInput.init()
    //   }, [])
    console.log("propsssss", props.location.state.state);
    const { data } = useParams()
    console.log("data", data)
    const initData = {
        "content": "",
        "subject": "",
    }

    const [formData, setFormData] = useState(initData)
    const [error, seterror] = useState({})

    useEffect(() => {
        var selval = props.location?.state?.state
        setFormData(selval)
    }, [])


    console.log("formData", formData);


    const onEditorChange = (evt) => {
        seterror({})
        var description_text = evt.editor.getData()
        console.log("description_text", description_text);
        let formdata = { ...formData, ...{ ['content']: description_text } }
        setFormData(formdata)
    }

    const validation = async () => {
        var error = {}

        if (isEmpty(formData.content)) { error.content = "Content Can't be Empty" };

        return error
    }


    const submitting = async () => {
        const validate = await validation()
        if (!isEmpty(validate)) {
            seterror(validate);
        } else {
            var updata = await editCms(formData);
            if (updata.success === "success") {
                alert("Edited successfully");
                history.push("/admin/cmsList")
            } else {
                alert("Not edited");
            }
        }
    }


    return (
        <>
            <div className="content">
                <Card>
                    <CardHeader>
                        <CardTitle tag="h1" >{formData.subject}</CardTitle>
                    </CardHeader>
                    <CardBody>
                        <label htmlFor="exampleInputName1">content</label>
                        {formData?.content &&
                            <CKEditor
                                initData={formData?.content}
                                onChange={onEditorChange}
                            />
                        }
                        <br></br><Button onClick={() => submitting()}>Submit</Button>
                        <br></br><span style={{ color: 'red' }}>{error && error.content}</span>
                    </CardBody>
                </Card>
            </div>
        </>
    )

}

export default EditCms
