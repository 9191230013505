/*!

=========================================================
* Black Dashboard React v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/black-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/black-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { isEmpty } from "config/common";
import { SavePairToken } from "../../../axiosCalls/admin.axios";
// import  translate from 'google-translate-free';
import axios from 'axios'
// reactstrap components
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    CardText,
    FormGroup,
    Form,
    Input,
    Row,
    Col
} from "reactstrap";

function Tokenadd() {
    let history = useHistory();
    const [error, seterror] = useState({})

    const [TokenA, setTokenA] = useState({
        contractAddress: "",
        currencyName: "",
        currencySymbol: "",
        tokenType: "",
        decimals: "",
        type: "Token"
    })

    const [TokenB, setTokenB] = useState({
        contractAddress: "",
        currencyName: "",
        currencySymbol: "",
        tokenType: "",
        decimals: "",
        type: "Token"
    })

    console.log("TokenA", TokenA);
    console.log("TokenB", TokenB);

    const oninputchange = async (from, e) => {
        seterror({})
        if (from == "TokenA") { setTokenA({ ...TokenA, [e.target.name]: e.target.value }) }
        if (from == "TokenB") { setTokenB({ ...TokenB, [e.target.name]: e.target.value }) }
    }


    const validation = async () => {
        var error = {};
        if (isEmpty(TokenA.currencyName)) { error.currencyNameA = "Currency Name can't be empty" }
        if (isEmpty(TokenA.currencySymbol)) { error.currencySymbolA = "currency Symbol can't be empty" }
        if (isEmpty(TokenA.tokenType)) { error.tokenTypeA = "token Type can't be empty" }
        if (isEmpty(TokenA.contractAddress)) { error.contractAddressA = "contractAddress can't be empty" }
        if (isEmpty(TokenA.decimals)) { error.decimalsA = "decimals can't be empty" }

        if (isEmpty(TokenB.currencyName)) { error.currencyNameB = "Currency Name can't be empty" }
        if (isEmpty(TokenB.currencySymbol)) { error.currencySymbolB = "currency Symbol can't be empty" }
        if (isEmpty(TokenB.tokenType)) { error.tokenTypeB = "token Type can't be empty" }
        if (isEmpty(TokenB.contractAddress)) { error.contractAddressB = "contractAddress can't be empty" }
        if (isEmpty(TokenB.decimals)) { error.decimalsB = "decimals can't be empty" }
        if (TokenA.tokenType != TokenB.tokenType) (error.SameTokenType = "TokenA TokenType And TokenB TokenType Should BE Same !!!")
        return error
    }



    const onClicking = async () => {
        var Gell = await validation();
        console.log("val", Gell);
        if (!isEmpty(Gell)) { seterror(Gell) }
        else {
            var sendData = { TokenA, TokenB }
            console.log("sendaaaaa", sendData);
            var savedData = await SavePairToken(sendData)
            console.log("asaafasefrefef", savedData);
            if(savedData.success == "success"){
                alert(savedData.msg);
                // window.reload()
                history.push("/admin/dashboard")
            }else{
                alert(savedData.msg)
                // window.reload()
            }
        }
    }

    return (
        <>
            <div className="content">
                <Row>
                    <Col md="12">
                        <Card>
                            <CardHeader>
                                <h5 className="title">Currency Token Add</h5>
                            </CardHeader>
                            <CardBody>
                                <Form>
                                    <Row>
                                        <Col md="8">
                                            <FormGroup>
                                                <h5 className="title">Token A</h5>
                                                <div>
                                                    <label>ContractAddress</label>
                                                    <br></br><span style={{ color: 'red' }}>{error && error.contractAddressA}</span>
                                                    <Input
                                                        placeholder="ContractAddress"
                                                        id="contractAddress"
                                                        name="contractAddress"
                                                        type="text"
                                                        onChange={(e) => oninputchange("TokenA", e)}
                                                    />
                                                </div>

                                                <div><br></br>
                                                    <label>Currency Name</label>
                                                    <br></br><span style={{ color: 'red' }}>{error && error.currencyNameA}</span>
                                                    <Input
                                                        placeholder="CurrencyName"
                                                        id="currencyName"
                                                        name="currencyName"
                                                        type="text"
                                                        onChange={(e) => oninputchange("TokenA", e)}
                                                    />
                                                </div>

                                                <div><br></br>
                                                    <label>Curency Symbol</label>
                                                    <br></br><span style={{ color: 'red' }}>{error && error.currencySymbolA}</span>
                                                    <Input
                                                        placeholder="CurrencySymbol"
                                                        id="currencySymbol"
                                                        name="currencySymbol"
                                                        type="text"
                                                        onChange={(e) => oninputchange("TokenA", e)}
                                                    />
                                                </div>

                                                <div><br></br>
                                                    <label>Decimal</label>
                                                    <br></br><span style={{ color: 'red' }}>{error && error.decimalsA}</span>
                                                    <Input
                                                        placeholder="Decimals"
                                                        id="decimals"
                                                        name="decimals"
                                                        type="number"
                                                        onChange={(e) => oninputchange("TokenA", e)}
                                                    />
                                                </div>

                                                <div><br></br>
                                                    <label>Token Type</label>
                                                    <br></br><span style={{ color: 'red' }}>{error && error.tokenTypeA}</span>
                                                    <Input
                                                        // placeholder="tokenType"
                                                        id="tokenType"
                                                        name="tokenType"
                                                        type="select"
                                                        onChange={(e) => oninputchange("TokenA", e)}
                                                    >
                                                        <option value={""} hidden={true}>Select</option>
                                                        <option value={"ERC20"}>ERC20</option>
                                                        <option value={"BEP20"}>BEP20</option>
                                                    </Input>
                                                </div>

                                            </FormGroup>
                                            <FormGroup>
                                                <br></br>
                                                <h5 className="title">Token B</h5>
                                                <div>
                                                    <label>ContractAddress</label>
                                                    <br></br><span style={{ color: 'red' }}>{error && error.contractAddressB}</span>
                                                    <Input
                                                        placeholder="ContractAddress"
                                                        id="contractAddress"
                                                        name="contractAddress"
                                                        type="text"
                                                        onChange={(e) => oninputchange("TokenB", e)}
                                                    />
                                                </div>

                                                <div><br></br>
                                                    <label>Currency Name</label>
                                                    <br></br><span style={{ color: 'red' }}>{error && error.currencyNameB}</span>
                                                    <Input
                                                        placeholder="CurrencyName"
                                                        id="currencyName"
                                                        name="currencyName"
                                                        type="text"
                                                        onChange={(e) => oninputchange("TokenB", e)}
                                                    />
                                                </div>

                                                <div><br></br>
                                                    <label>Curency Symbol</label>
                                                    <br></br><span style={{ color: 'red' }}>{error && error.currencySymbolB}</span>
                                                    <Input
                                                        placeholder="CurrencySymbol"
                                                        id="currencySymbol"
                                                        name="currencySymbol"
                                                        type="text"
                                                        onChange={(e) => oninputchange("TokenB", e)}
                                                    />
                                                </div>

                                                <div><br></br>
                                                    <label>Decimal</label>
                                                    <br></br><span style={{ color: 'red' }}>{error && error.decimalsB}</span>
                                                    <Input
                                                        placeholder="Decimals"
                                                        id="decimals"
                                                        name="decimals"
                                                        type="number"
                                                        onChange={(e) => oninputchange("TokenB", e)}
                                                    />
                                                </div>

                                                <div><br></br>
                                                    <label>Token Type</label>
                                                    <br></br><span style={{ color: 'red' }}>{error && error.tokenTypeB}</span>
                                                    <Input
                                                        // placeholder="tokenType"
                                                        id="tokenType"
                                                        name="tokenType"
                                                        type="select"
                                                        onChange={(e) => oninputchange("TokenB", e)}
                                                    >
                                                        <option value={""} hidden={true}>Select</option>
                                                        <option value={"ERC20"}>ERC20</option>
                                                        <option value={"BEP20"}>BEP20</option>
                                                    </Input>
                                                </div>

                                            </FormGroup>
                                        </Col>
                                    </Row>
                                </Form>
                                {<span style={{ color: '#ffff00' }}>{error && error.SameTokenType}</span>}
                            </CardBody>
                            <CardFooter>
                                <Button className="btn-fill" color="primary" type="submit" onClick={() => onClicking()}>
                                    Pair
                                </Button>
                            </CardFooter>
                        </Card>
                    </Col>

                </Row>
            </div>
        </>
    );
}

export default Tokenadd;
