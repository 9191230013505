/*!

=========================================================
* Black Dashboard React v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/black-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/black-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import Ladd from "screens/language/add";
import LList from "screens/language/list";
import LEdit from "screens/language/edit";
import Dashboard from "views/Dashboard.js";
import Login from './components/Admin/login.js'
import USERList from './components/user/userlist.js'
import USERadd from "./components/user/useradd.js";
import SubAdminadd from "components/Admin/subadminadd.js";
import SubAdminList from "./components/Admin/subadminlist.js";
import currencyCryptoList from './components/Admin/currency/currencyCryptoList'
import currencyTokenList from './components/Admin/currency/currencyTokenList'
import cryptoAdd from './components/Admin/currency/addCrypto';
import AddToken from './components/Admin/currency/addToken';
import CmsList from "./components/Admin/cms/cmcList.js";
import cmsEdit from './components/Admin/cms/editCms';
import dappCategoryList from './components/Admin/dapp/dappCategoryList'
import AddappCategory from './components/Admin/dapp/AddappCategory'
import DappsList from './components/Admin/dapp/dappsList'
import addDapps from './components/Admin/dapp/addDapps'
import Icons from "views/Icons.js";
import Map from "views/Map.js";
import Notifications from "views/Notifications.js";
import Rtl from "views/Rtl.js";
import TableList from "views/TableList.js";
import Typography from "views/Typography.js";
import UserProfile from "views/UserProfile.js";
import './assets/css/index.css'
import AdminList from "components/Admin/adminlist.js";
import Adminadd from "components/Admin/addadmin.js";
import tokens_pair from 'components/Admin/tokens/pair_Token';
import tokens_list from 'components/Admin/tokens/tokens_list';

import StakeAdd from 'components/Admin/stake/addstake'
import StakeList from 'components/Admin/stake/poolList'

var routes = [
  // {
  //   path: "/dashboard",
  //   name: "Dashboard",
  //   rtlName: "لوحة القيادة",
  //   icon: "tim-icons icon-chart-pie-36",
  //   component: Dashboard,
  //   layout: "/admin"
  // },
  {
    path: "/login",
    name: "Login",
    rtlName: "لوحة القيادة",
    icon: "tim-icons icon-chart-pie-36",
    component: Login,
    layout: "/"
  },

  {
    path: "/currencyCryptoList",
    name: "Currency - Crypto",
    rtlName: "لوحة القيادة",
    icon: "tim-icons icon-chart-pie-36",
    component: currencyCryptoList,
    layout: "/admin"
  },

  {
    path: "/currencyTokenList",
    name: "Currency - Token",
    rtlName: "لوحة القيادة",
    icon: "tim-icons icon-chart-pie-36",
    component: currencyTokenList,
    layout: "/admin"
  },

  {
    path: "/cryptoAdd",
    name: "AdminAdd",
    rtlName: "قائمة الجدول",
    // icon: "tim-icons icon-puzzle-10",
    component: cryptoAdd,
    layout: ""
  },

  {
    path: "/AddToken",
    name: "AdminAdd",
    rtlName: "قائمة الجدول",
    // icon: "tim-icons icon-puzzle-10",
    component: AddToken,
    layout: ""
  },

  // {
  //   path: "/cmsList",
  //   name: "Cms List",
  //   rtlName: "قائمة الجدول",
  //   icon: "tim-icons icon-chart-pie-36",
  //   component: CmsList,
  //   layout: "/admin"
  // },


  {
    path: "/editCms",
    name: "Cms List",
    rtlName: "قائمة الجدول",
    icon: "tim-icons icon-chart-pie-36",
    component: cmsEdit,
    layout: ""
  },

  {
    path: "/dappCategoryList",
    name: "dapp Category List",
    rtlName: "قائمة الجدول",
    icon: "tim-icons icon-chart-pie-36",
    component: dappCategoryList,
    layout: "/admin"
  },


  {
    path: "/AddappCategory",
    name: "Add app Category",
    rtlName: "قائمة الجدول",
    // icon: "tim-icons icon-chart-pie-36",
    component: AddappCategory,
    layout: ""
  },

  {
    path: "/DappsList",
    name: "DappsList",
    rtlName: "قائمة الجدول",
    icon: "tim-icons icon-chart-pie-36",
    component: DappsList,
    layout: "/admin"
  },

  {
    path: "/AddDapps",
    name: "addDapps",
    rtlName: "قائمة الجدول",
    icon: "tim-icons icon-chart-pie-36",
    component: addDapps,
    layout: ""
  },

  //  {
  //   path: "/saveDappCategory",
  //     name: "saveDappCategory",
  //     rtlName: "قائمة الجدول",
  //     // icon: "tim-icons icon-chart-pie-36",
  //     component: saveDappCategory,
  //     layout: ""
  //  },
  // {
  //   path: "/user-profile",
  //   name: "User Profile",
  //   rtlName: "ملف تعريفي للمستخدم",
  //   icon: "tim-icons icon-single-02",
  //   component: UserProfile,
  //   layout: "/admin"
  // },
  // {
  //   path: "/language",
  //   name: "Language",
  //   rtlName: "قائمة الجدول",
  //   icon: "tim-icons icon-puzzle-10",
  //   component: LList,
  //   layout: "/admin"
  // },
  {
    path: "/language-add",
    name: "Language-Add",
    rtlName: "قائمة الجدول",
    // icon: "tim-icons icon-puzzle-10",
    component: Ladd,
    layout: ""
  },
  {
    path: "/language-edit",
    name: "Language-edit",
    rtlName: "قائمة الجدول",
    // icon: "tim-icons icon-puzzle-10",
    component: LEdit,
    layout: ""
  },

  {
    path: "/useradd",
    name: "UserAdd",
    rtlName: "قائمة الجدول",
    // icon: "tim-icons icon-puzzle-10",
    component: USERadd,
    layout: ""
  },
  //  {
  //   path: "/userlist",
  //     name: "UserList",
  //     rtlName: "قائمة الجدول",
  //     // icon: "tim-icons icon-puzzle-10",
  //     component: USERList,
  //     layout: ""
  //  },


  {
    path: "/userlist",
    name: "USERPROFILE",
    rtlName: "قائمة الجدول",
    icon: "tim-icons icon-single-02",
    component: USERList,
    layout: "/admin"
  },

  {
    path: "/adminadd",
    name: "AdminAdd",
    rtlName: "قائمة الجدول",
    // icon: "tim-icons icon-puzzle-10",
    component: Adminadd,
    layout: ""
  },


  // {

  //   path: "/adminlist",
  //   name: "AdminProfile",
  //   rtlName: "قائمة الجدول",
  //   icon: "tim-icons icon-single-02",
  //   component: AdminList,
  //   layout: "/admin"
  // },
  {
    path: "/subadminadd",
    name: "SubAdminAdd",
    rtlName: "قائمة الجدول",
    // icon: "tim-icons icon-puzzle-10",
    component: SubAdminadd,
    layout: ""
  },

  // {
  //   path: "/Subadminlist",
  //   name: "SubADMINProfile",
  //   rtlName: "قائمة الجدول",
  //   icon: "tim-icons icon-single-02",
  //   component: SubAdminList,
  //   layout: "/admin"
  // },


  {
    path: "/Tokens",
    name: "Tokens Pair",
    rtlName: "قائمة الجدول",
    icon: "tim-icons icon-single-02",
    component: tokens_pair,
    layout: "/admin"
  },
  {
    path: "/Tokens-list",
    name: "Tokens Pair",
    rtlName: "قائمة الجدول",
    icon: "tim-icons icon-single-02",
    component: tokens_list,
    layout: ""
  },

  {
    path: "/stakelist",
    name: "Stake List",
    rtlName: "قائمة الجدول",
    icon: "tim-icons icon-single-02",
    component: StakeList,
    layout: "/admin"
  },
  {
    path: "/stakeadd",
    name: "Stake Add",
    rtlName: "قائمة الجدول",
    icon: "tim-icons icon-single-02",
    component: StakeAdd,
    layout: "/"
  },



];
export default routes;
